
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
export default defineComponent({
  name: "CreateNewPassword",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const confirmPassword = "";
    const password = "";

    const submitButton = ref<HTMLButtonElement | null>(null);

    const changePassword = Yup.object().shape({
      password: Yup.string().required().label("Password"),
      confirmPassword: Yup.string().required().label("Confirm Password").oneOf([Yup.ref('password')], 'Your passwords do not match.')
    });

    const onSubmitCreatePassword = async (values) => {
      if (submitButton.value) {
        submitButton.value!.disabled = true;
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      if (values.password === values.confirmPassword) {
        store.commit("setAuthConfigPassword", values.password);

        store.dispatch('bankCreateNewPassword', {
          newPassword: values.password
        })
          .then((res) => {
            if (res.status === 200) {
              redirectToRouteWithName("bank-register/personal-info");
            }
          })
          .finally(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            submitButton.value!.disabled = false;
          })
      }
    };

    return {
      onSubmitCreatePassword,
      changePassword,
      submitButton,
      password,
      confirmPassword
    };
  }
});
